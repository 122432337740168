
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrderService {
    private URL = ''

    constructor(
        private http: HttpClient
    ) {
        this.URL = environment.domain
    }

    // generar orden
    generateOrder(data:any) {
        const url_api = this.URL + 'api/em/order/set'
        return this.http.post(url_api, data);
    }

    // MIS ORDENES
    getListOrderByUser(data: any) {
        const url_api = this.URL + 'api/em/order/get_filter/user'
        return this.http.post(url_api, data);
    }

    getOrderByCode(code:string) {
        const url_api = this.URL + 'api/em/order/by_code/' + code
        return this.http.get(url_api);
    }

    getImagePay(code: string) {
        const url_api = this.URL + 'api/market/order_pay/file/' + code
        return url_api
    }

    private orderCodeSource = new BehaviorSubject<string | null>(null);
    orderCode$ = this.orderCodeSource.asObservable();

    setOrderCode(code: string) {
      this.orderCodeSource.next(code);
    }

    private orderDetails: any;

    setOrderDetails(order: any){
        this.orderDetails = order
    }

    getOrderDetails(){
        return this.orderDetails
    }
}