import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const title = "Casa del Café"
const description = "Casa del Café"

const routes: Routes = [
  {
		path: 'auth',
		loadChildren: () => import( './auth/auth.module').then( m => m.AuthModule),
		data: { title: title, description: description}
	},
  {
		path: '',
		component: LayoutComponent,
		children: [
      {
        path: '',loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      }
    ]
  },
  { path: '**', component: NotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
