
<div class="container py-5 mb-lg-3">
    <div class="row justify-content-center pt-lg-4 text-center">
        <div class="col-lg-5 col-md-7 col-sm-9">
            <h1 class="display-404 py-lg-3">404</h1>
            <h2 class="h3 mb-4">No podemos encontrar la página que estás buscando</h2>
            <p class="fs-md mb-4">
                <u>Aquí tienes algunos enlaces útiles:</u>
            </p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-10">
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <a class="card h-100 border-0 shadow-sm" routerLink="/">
                        <div class="card-body">
                            <div class="d-flex align-items-center"><i class="ci-home text-primary h4 mb-0"></i>
                                <div class="ps-3">
                                <h5 class="fs-sm mb-0">Inicio</h5><span class="text-muted fs-ms">Volver a la página principal</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-sm-6 mb-3">
                    <a class="card h-100 border-0 shadow-sm" routerLink="/contactanos">
                        <div class="card-body">
                        <div class="d-flex align-items-center"><i class="ci-help text-info h4 mb-0"></i>
                            <div class="ps-3">
                            <h5 class="fs-sm mb-0">Ayuda y Soporte</h5><span class="text-muted fs-ms">Visita nuestro centro de ayuda</span>
                            </div>
                        </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

