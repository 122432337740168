import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  @Input() note: string = ''; 

    constructor(public dialogRef: MatDialogRef<ConfirmComponent>) {}
  
    onConfirm(): void {
      this.dialogRef.close(true); 
    }
  
    onCancel(): void {
      this.dialogRef.close(false);
    }
} 