
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class StoreService {
    private URL = ''

    constructor(
        private http: HttpClient) {
        this.URL = environment.domain
    }

    getListStoreByProvider(data: any) {
        const url_api = this.URL + 'api/em/store/get_filter'
        return this.http.post(url_api, data);
    }

    getStoreId(id: any) {
        const url_api = this.URL + 'api/em/store/validate_store/' + id
        return this.http.get(url_api);
    }

    getStoreByAddress(data: any){
        const url_api = this.URL + 'api/em/store/get_store/by_address'
        return this.http.post(url_api, data);
    }
}