<div class="content-pay">
  <mat-card class="card" style="border-radius: 10px; padding: 16px;">
    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <img style="height: 55px; object-fit: contain;" src="../../../assets/img/footer-logo-dark.png"
          alt="Casa del Café">
        <h2 style="margin: 0; margin-left: auto;">{{data.price | currency}}</h2>
      </div>
    </div>
  </mat-card>

  <mat-card class="card" style="margin-top: 10px; border-radius: 10px;">
    <div>
      <h3>Transacción Segura</h3>
    </div>
    <div>
      <p>Para tu tranquilidad, deseamos informarte que tus datos personales y transacción de pago estarán siendo
        procesados a través del método seguro, certificado por First Atlantic Commerce.</p>
    </div>
    <div style="margin-top: 10px;">
      <button class="button-continue text-light" mat-raised-button style="margin-bottom: 10px; width: 100%;"
        (click)="viewHtmlPay()">Continuar</button>
      <button mat-button style="width: 100%;" (click)="close()">Cancelar</button>
    </div>
  </mat-card>

  <mat-card class="card" style="margin-top: 10px; border-radius: 10px;">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" class="mb-2">
        <div fxLayout="row">
          <img style="height: 35px;" src="../../../assets/images/others/VisaSecure.jpg" alt="Visa Secure">
          <img style="height: 35px;" src="../../../assets/images/others/MC-ID-Check.png" alt="MC ID">
          <img style="height: 35px;" src="../../../assets/images/others/fac.jpg" alt="fac">
        </div>
      </div>
      
    </div>
  </mat-card>
</div>