<div class="modal-header">
  <h5 class="modal-title">Confirmación</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()" style="border: none; background: transparent; outline: none;">
    <span aria-hidden="true" style="font-size: 1.5rem; color: #6c757d; font-weight: bold;">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Artículo añadido con éxito. Ahora tienes {{ itemCount }} artículo(s) en tu carrito.</p>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" (click)="navigateToProducts()">Seguir Comprando</button>
  <button class="btn btn-primary" (click)="navigateToCart()">Ver mi Carrito</button>
</div>
