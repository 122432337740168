import { Injectable } from '@angular/core';
import { SnackBarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagementService {

    constructor(
        private snackBarService: SnackBarService
    ) { }

    getErrorMessage(error: any) {
        if (!navigator.onLine) {
            this.snackBarService.error({ message: "No hay conección a internet" })
        }
        else if (error instanceof ErrorEvent) { // client-side error
            this.getClientMessage(error)
        } else {
            // backend error
            this.getServerMessage(error)
        }
    }

    private getClientMessage(error: ErrorEvent) {
        if (error.error == undefined) {
            this.snackBarService.error({ message: "Error" })
        } else {
            this.snackBarService.error(error.error.message ? error.error.message : error.toString())
        }
    }

    private getServerMessage(error: any) {
        if (error.status == undefined || error.status == 401) {
            if (String(error.error.message).toLowerCase() == 'jwt expired') {
                this.snackBarService.error({ message: 'Su sesión a expirado' })
            } else {
                this.snackBarService.error({ message: error })
            }

        } else {
            this.snackBarService.error({ message: error.error.message })
        }
    }
}