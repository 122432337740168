import { PaymentService } from '../../services/ecommerce/payment.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-load-pay',
  templateUrl: './load-pay.component.html',
  styleUrls: ['./load-pay.component.scss']
})
export class LoadPayComponent implements OnInit {


  previewData: any
  load: boolean = false


  state = 'LOAD' // 'ERROR' 'SUCCESS' ,'DUPLICATE'
  interval: any;
  orderCode;
  messageError = ''
  max = 60000
  init = 0
  constructor(
    private router: Router,
    public paymentService: PaymentService,
    public dialogRef: MatDialogRef<LoadPayComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orderCode = this.data
  }

  ngOnInit() {

    this.interval = setInterval(() => {
      this.onGetLogOrder()
    }, 10000);
  }

  onGetLogOrder() {
    this.paymentService.getLogPayment(this.orderCode).subscribe((res: any) => {

      if (res.reason == 'waiting') {
        this.state = 'LOAD'
        this.init = this.init + 10000
        if (this.init >= this.max) {
          this.state = 'EXPIRED'
          clearInterval(this.interval)
        }
        return
      }
      if (res.reason == 'success') {
        this.state = 'SUCCESS'
        clearInterval(this.interval)
        return
      }

      if (res.reason == 'duplicate') {
        this.state = 'DUPLICATE'
        this.messageError = `El pedido ${this.data} ya cuenta con un método de pago activo.`
        clearInterval(this.interval)
        return
      }
      if (res.reason != 'duplicate' && res.reason != 'success' && res.reason != 'waiting') {
        this.state = 'ERROR'
        this.messageError = res.reason
        clearInterval(this.interval)
        return
      }
    }, (error: any) => {
      console.error('Error:', error);
    })
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  closeSuccess() {
    this.dialogRef.close(this.state)
  }

  irMisPedidos() {
    this.dialogRef.close(this.state)
    this.router.navigate(["/cuenta/pedidos"])
  }

}

