import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContinuarComprandoComponent } from 'src/app/pages/cart/continuar-comprando/continuar-comprando.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './controls2-component.component.html',
  styleUrls: ['./controls2-component.component.scss'],
})
export class Controls2ComponentComponent {
  @Input() products: any[] = [];
  @Input() aditional: any[] = [];
  @Input() aditionalSelected: any[] = [];
  @Input() note: string = '';

  private _user: any = null;
  isPromotion =true;
  constructor(
    private ecommerceService: EcommerceService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.authService.currentUser.subscribe((res) => {
      this._user = res ? res.user : null;
    });
  }

  ngOnInit() {
    if (!this.aditionalSelected) {
      this.aditionalSelected = this.products.map(() => ({ selected: [] }));
    }
  }

  public async addToCart() {
    if (!this._user) {
      this.snackBar.open('Por favor, inicie sesión para agregar productos al carrito', '×', {
        panelClass: 'error',
        verticalPosition: 'top',
        duration: 5000,
      });

      setTimeout(() => {
        this.router.navigate(['/auth/ingresar']);
      }, 2000);
      return;
    }

    console.log('Productos recibidos en addToCart:', this.products);

    const productsToAdd = this.products.map((product, index) => {
      if (product.type === 'A') {
        if (
          this.aditional[index]?.length > 0 &&
          !this.onVerifiedQuantitySubnivel(this.aditionalSelected[index])
        ) {
          this.snackBar.open('Por favor, completa las selecciones requeridas', '×', {
            panelClass: 'error',
            verticalPosition: 'top',
            duration: 5000,
          });
          return null;
        }
      }

      let additionalDetail: any[] = [];
      if (this.aditional[index]?.length > 0) {
        this.aditionalSelected[index]?.selected?.forEach((item: any) => {
          if (!item.material?.type) {
            additionalDetail.push(item);
          } else {
            item.aditionalSelected?.selected?.forEach((subItem: any) => {
              additionalDetail.push(subItem);
            });
          }
        });
      }

      const auxProduct = {
        ...product,
        note: this.note,
        cartCount: 1,
      };

      if (product.type === 'A') {
        auxProduct.aditional.aditionalDetail = this.parseAdditional(
          this.aditional[index],
          additionalDetail
        );
        auxProduct.newPrice = this.getTotalItemSelected(product, this.aditionalSelected[index]?.selected || []);
      }

      return auxProduct;
    });


    const validProducts = productsToAdd.filter((product) => product !== null);

    if (validProducts.length === 0) {
      return;
    }

    validProducts.forEach((product) => {
      product.isPromotion = true;
      this.ecommerceService.addToCart(product);
    });
    console.log("enviar carrito: ",validProducts)
    const modalRef = this.modalService.open(ContinuarComprandoComponent);
    modalRef.componentInstance.itemCount = validProducts.length;
  }

  parseAdditional(aditional: any, additionalDetail: any) {
    console.log("products flujo promocion",this.products)
    console.log("aditional flujo promocion",this.aditional)
    console.log("aditionalSelected flujo promocion",this.aditionalSelected)
    console.log("note flujo promocion",this.note)

    return aditional.aditionalDetail.map((item: any) => {
      const hasDetails = additionalDetail.some((detail: any) => detail.id === item.id);
      return {
        ...item,
        hasBeenAdded: hasDetails,
        isActiveToAddInApp: null,
      };
    });
  }

  onVerifiedQuantitySubnivel(aditionalSelected: any) {
    console.log("test: ", aditionalSelected.selected)
    for (const item of aditionalSelected.selected) {
      if (item.material.type === 'A' && item.material.aditional.quantity > 0) {
        const sum = item.aditionalSelected.selected.reduce((acc: number, i: any) => acc + i.quantity, 0);
        if (!(sum >= item.material.aditional.minQuantity && sum <= item.material.aditional.quantity)) {
          return true;
        }
      }
    }
    return false;
  }

  getTotalItemSelected(product: any, selected: any[]) {
    let total = Number(product?.newPrice || 0);
    if (product?.type === 'A') {
      selected.forEach((item: any) => {
        if (item.material.type === 'A') {
          item.aditionalSelected.selected.forEach((subItem: any) => {
            if (subItem.isAditionalPrice === '1') {
              total += Number(subItem.price) * subItem.quantity;
            }
          });
        }
      });
    }
    return total;
  }
}
