<div class="mb-3 d-flex align-items-center justify-content-center">
  <button class="btn-circle btn btn-minus" (click)="minus()" [disabled]="quantity <= 1" [ngClass]="{'disabled-button': quantity <= 1}">
    <img src="../../../assets/img/remove-svgrepo-com.svg" alt="minus icon">
  </button>
  <span class="mx-2">{{ quantity }}</span>
  <button class="btn-circle btn btn-plus me-3" (click)="plus()">
    <img src="../../../assets/img/circle-plus-svgrepo-com.svg" alt="plus icon">
  </button>
  <button class="btn btn-primary btn-shadow d-block w-100" type="submit" (click)="addToCart(product)">
    Guardar
  </button>
</div>