<mat-dialog-content style="max-height: 57vh;">
  <div style="padding: 20px;">
    <div fxLayout="column">
      <div *ngIf="this.state == 'LOAD'" fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-spinner [diameter]="70"></mat-spinner>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <h2>Procesando pago, por favor espere ...</h2>
        </div>
      </div>
      <div *ngIf="this.state == 'SUCCESS'" fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-icon style="font-size: 73px; height: auto; width: auto; color:rgb(22, 185, 49)">check_circle</mat-icon>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <h4>
            Pago realizado con éxito.
          </h4>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <button class="btn-load text-light" mat-raised-button (click)="closeSuccess()">ACEPTAR</button>
        </div>
      </div>
      <div *ngIf="this.state == 'DUPLICATE'" fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-icon style="font-size: 73px; height: auto; width: auto;" color="primary">warning</mat-icon>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <h4>
            {{this.messageError}}
          </h4>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <button class="btn-load text-light" mat-raised-button (click)="closeSuccess()">ACEPTAR</button>
        </div>
      </div>
      <div *ngIf="this.state == 'ERROR'" fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-icon style="font-size: 73px; height: auto; width: auto;" color="warn">error</mat-icon>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <h4>
            {{this.messageError}}
          </h4>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <button color="primary" mat-raised-button (click)="closeSuccess()">VOLVER A INTENTAR</button>
        </div>
      </div>
      <div *ngIf="this.state == 'EXPIRED'" fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-icon style="font-size: 73px; height: auto; width: auto;" color="warn">assignment_late</mat-icon>
        </div>
        <div fxLayoutAlign="center center" fxFlex="100" class="mt-10">
          <h4>
            Tiempo de espera excedido, el pago no fue procesado. Por favor verifique que su navegador no bloquee
            ventanas
            emergentes o diríjase a "Mis Pedidos" para ver el estado de tu pedido.
          </h4>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxFlex="100" class="mt-10">
          <button ngClass.xs="mt-10" color="primary" mat-raised-button (click)="closeSuccess()">VOLVER A
            INTENTAR</button>
          <span></span>
          <button ngClass.xs="mt-10" color="primary" mat-raised-button (click)="irMisPedidos()">IR A MIS
            PEDIDOS</button>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>