<div (window:scroll)="windowScroll()" id="home">
  <header class="navbar d-block navbar-sticky navbar-expand-lg navbar-light bg-light header-color">
    <div class="container"><a class="navbar-brand d-none d-sm-block me-4 order-lg-1" routerLink="/"><img
          src="assets/img/logo-dark.png" width="50" alt=""></a><a class="navbar-brand d-sm-none me-2 order-lg-1"><img
          src="assets/img/logo-icon.png" width="50" alt="Casa del Café"></a>
      <div class="navbar-toolbar d-flex align-items-center order-lg-3">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"><span
            class="navbar-toggler-icon" (click)="collapse.toggle()"></span></button><a
          class="navbar-tool d-none d-lg-flex" href="javascript:void(0)" data-bs-toggle="collapse"
          data-bs-target="#searchBox" role="button" aria-expanded="false" aria-controls="searchBox"><span
            class="navbar-tool-tooltip">{{"TRANSLATE.SEARCH" | translate}}</span>
          <div class="navbar-tool-icon-box" (click)="collapse.toggle()"><i
              class="navbar-tool-icon ci-search font-color"></i></div>
        </a>
        <div ngbDropdown class="btn-group dropdown disable-autohide">
          <button class="btn text-language btn-outline-light border-light btn-sm dropdown-toggle px-2" type="button"
            data-bs-toggle="dropdown" ngbDropdownToggle>
            <img class="me-2" src="{{flagvalue}}" width="20" alt="">{{selectedLanguage}}
          </button>
          <ul ngbDropdownMenu class="dropdown-menu my-1">
            @for(item of listLang;track $index){
            <li (click)="setLanguage(item.text, item.lang, item.flag,item.language)">
              <a class="dropdown-item pb-1" [ngClass]="{'active': cookieValue === item.lang}"
                href="javascript:void(0);">
                <img class="me-2" src="{{item.flag}}" width="20" alt="{{item.text}}">{{item.text}}
              </a>
            </li>
            }
          </ul>
        </div>
        @if(this._user){
        <div class="btn-group dropdown" ngbDropdown>
          <a mat-button class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            ngbDropdownToggle>
            <i class="navbar-tool-icon ci-user text-primary"></i>
          </a>
          <div class="dropdown-menu my-1" ngbDropdownMenu>
            <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/informacion">
              <i class="ci-user opacity-60 me-2"></i>{{'ACCOUNT.ACCOUNT' | translate}}
            </a>
            <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/comentario">
              <i class="ci-book opacity-60 me-2"></i>{{'ACCOUNT.SEND_FEEDBACK' | translate}}
            </a>
            <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/tarjetas">
              <i class="ci-card opacity-60 me-2"></i>{{'ACCOUNT.PAYMENT_METHODS' | translate}}
            </a>
            <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/pedidos">
              <i class="ci-bag opacity-60 me-2"></i>{{'ACCOUNT.MY_ORDERS' | translate}}
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0);" class="dropdown-item" (click)="logout()">
              <i class="ci-sign-out opacity-60 me-2"></i>{{'TRANSLATE.SIGN_OUT' | translate}}
            </a>
          </div>
        </div>
        <!-- si es cero no mostrar -->
        <div *ngIf="ecommerceService.Data.totalCartCount > 0" class="navbar-tool ms-3 cart">
          <!-- Botón para abrir/cerrar el menú -->
          <a
            class="navbar-tool-icon-box bg-secondary"
            (click)="toggleDropdown()"
            role="button">
            <span class="navbar-tool-label">{{ecommerceService.Data.totalCartCount}}</span>
            <i class="navbar-tool-icon ci-cart"></i>
          </a>

          <!-- Menú desplegable -->
          <div
            class="dropdown-menu dropdown-menu-end"
            [ngClass]="{'show': isDropdownOpen}"
            *ngIf="isDropdownOpen"
          >
            <div class="widget widget-cart px-3 pt-2 pb-3" style="width: 20rem;">
              <!-- Contenedor de la lista de productos con desplazamiento -->
              <div style="max-height: 15rem; overflow-y: auto; overflow-x: hidden;" data-simplebar data-simplebar-auto-hide="false">
                @for(cart of ecommerceService.Data.cartList; track $index){
                <ng-container *ngIf="cart.material_json as material">
                  <div class="widget-cart-item pb-2 border-bottom">
                    <button
                      class="btn-close text-danger"
                      type="button"
                      aria-label="Remove"
                      (click)="removecart(cart.id)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="d-flex align-items-center">
                      <a class="d-block" href="javascript:void(0);">
                        <img
                          [src]="(material.images?.length > 0) ? ecommerceService.getPathImageSmall(material.images[0].fileName) : 'assets/images/others/not-found.png'"
                          alt="{{material.images?.[0]?.fieldName}}"
                          onerror="this.src='assets/images/others/not-found.png';" width="64">
                      </a>
                      <div class="ps-2">
                        <h6 class="widget-product-title">
                          @if(material.isPromotion){ ✨}
                          <a href="javascript:void(0);">{{material.name}}</a>
                          @if(material.isPromotion){ ✨}
                        </h6>
                        <div class="widget-product-meta">
                          <span class="text-accent me-2 text-black">${{material.newPrice | number: '1.2-2'}}</span>
                          <span class="text-black">x {{material.cartCount}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                }
              </div>

              <!-- Total y botón "Ver orden" siempre visibles al final -->
              <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                <span>Total:</span>
                <div class="widget-cart-total ms-auto text-end" style="flex-grow: 1;">
                  <span class="text-accent text-black">${{ totalPrice.toFixed(2) }}</span>
                </div>
              </div>

              <button class="btn btn-primary btn-sm d-block w-100" [routerLink]="['/cart']">Ver orden</button>
            </div>
          </div>
        </div>


        }@else {
        <a class="navbar-tool ms-2" href="javascript:void(0);" [routerLink]="['/auth/ingresar']"><span
            class="navbar-tool-tooltip">{{"ACCOUNT.ACCOUNT" | translate}}</span>
          <div class="navbar-tool-icon-box"><i class="navbar-tool-icon ci-user"></i></div>
        </a>
        }

      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="collapse navbar-collapse me-auto order-lg-2"
        id="navbarCollapse">
        <!-- Search (mobile)-->
        <div class="d-lg-none py-3">
          <div class="input-group"><i class="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <input class="form-control rounded-start" type="text" [(ngModel)]="term"
              [placeholder]="'TRANSLATE.WHAT_DO_U_NEED' | translate">
          </div>
        </div>
        <!-- Location dropdown-->
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color dropdown-toggle ps-lg-0" href="javascript:void(0);"
              routerLink="/ubicacion-restaurantes">{{'MENUITEMS.ORDER_NOW' | translate}}</a>

          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/nosotros">{{
              "MENUITEMS.ABOUT" | translate }}</a></li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/carreras">{{
              "MENUITEMS.CAREER" | translate }}</a></li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/noticia">{{
              "MENUITEMS.NEWS" | translate }}</a></li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/cdc-recompensas">{{
              "MENUITEMS.REWARDSCDC" | translate }}</a></li>
        </ul>
      </div>
    </div>
    <!-- Search collapse-->
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="search-box collapse" id="searchBox">
      <div class="container py-2">
        <div class="input-group"><i class="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
          <input class="form-control rounded-start" type="text" [placeholder]="'TRANSLATE.WHAT_DO_U_NEED' | translate">
        </div>
      </div>
    </div>
  </header>
</div>

<div class="handheld-toolbar">
  <div class="d-table table-layout-fixed w-100">
    <a class="d-table-cell handheld-toolbar-item" href="javascript:void(0)"
      [routerLink]="this._user ? ['/cuenta/informacion'] : ['/auth/ingresar']">
      <span class="handheld-toolbar-icon"><i class="ci-user"></i></span>
      <span class="handheld-toolbar-label">{{"ACCOUNT.ACCOUNT" | translate}}</span>
    </a>
    <a class="d-table-cell handheld-toolbar-item" href="javascript:void(0)" data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse" (click)="collapse.toggle()" onclick="window.scrollTo(0, 0)">
      <span class="handheld-toolbar-icon"><i class="ci-menu"></i></span>
      <span class="handheld-toolbar-label">Menu</span>
    </a>
    <a class="d-table-cell handheld-toolbar-item" href="javascript:void(0)" routerLink="/cart">
      <span class="handheld-toolbar-icon"><i class="ci-cart"></i>
        <span class="badge bg-primary rounded-pill ms-1">{{carts.length}}</span>
      </span>
      <span class="handheld-toolbar-label">${{calculatetotal(total)}}</span>
    </a>
  </div>
</div>
