import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-open-pay-popup',
  templateUrl: './open-pay-popup.component.html',
  styleUrls: ['./open-pay-popup.component.scss']
})
export class OpenPayPopupComponent implements OnInit {
  dataAux = null
  note: any
  constructor(public dialogRef: MatDialogRef<OpenPayPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {

  }

  viewHtmlPay() {
    try {
      let popupWin = window.open(' ', '_blank');
      if (popupWin) {
        popupWin.document.write(this.data.html);
        popupWin.document.close();
      } else {
        console.error('No se pudo abrir la ventana emergente.');

      }
    } catch (error) {

    }

    this.dialogRef.close("success")
  }
  close() {
    this.dialogRef.close("cancel");
  }
}