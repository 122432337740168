
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentService {
    private URL = ''

    constructor(
        private http: HttpClient) {
        this.URL = environment.domain
    }

    // metodo de pago
    getListPayMethod() {
        const url_api = this.URL + 'api/em/payment_method/get'
        return this.http.get(url_api);
    }

    getListBank() {
        const url_api = this.URL + 'api/em/bank/get'
        return this.http.get(url_api);
    }

    getListTranfersByBank(data: any) {
        const url_api = this.URL + 'api/em/bank_transfers/get'
        return this.http.post(url_api, data);
    }

    // crear order_pay
    saveOrderPay(data: FormData) {  //data es formData
        const url_api = this.URL + 'api/em/order_pay/set'
        return this.http.post(url_api, data);
    }

    getLogPayment(code: any) {
        const url_api = this.URL + 'api/em/order/log/get/' + code
        return this.http.get(url_api);
    }

    private currentPointsSubject = new BehaviorSubject<any>(null); 
    public currentPoints$ = this.currentPointsSubject.asObservable(); 
    getPoints() {
        const url_api = this.URL + 'api/em/fidelization/get_points';
        return this.http.get<any>(url_api)
            .pipe(
                map(res => {
                    sessionStorage.setItem('currentPoints', JSON.stringify(res));
                    console.log("Puntos obtenidos y guardados en sessionStorage:", res);
                    this.currentPointsSubject.next(res);
                    return res;
                })
            );
    }

    // update state order
    updateStateOrder(code: any, data: any) {
        const url_api = this.URL + 'api/em/order/set_state/' + code
        return this.http.post(url_api, data);
    }
}