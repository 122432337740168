import { PROVIDER } from '../../../environments/config';
import { environment } from '../../../environments/environment';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { NotificationService } from '../global/notificacion.service';
import { User } from 'src/app/core/models/user.model';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private URL = ''
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private currentPointsSubject: BehaviorSubject<any>;
    public currentPoints: Observable<any>;

  constructor(
    private http: HttpClient, 
    private router: Router, 
    public snackBar: MatSnackBar, 
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId: Object) 
    {
        this.URL = environment.domain
        const storedCurrentUser = localStorage.getItem('currentUser')
        const parsedCurrentUser = storedCurrentUser ? JSON.parse(storedCurrentUser) : null
        this.currentUserSubject = new BehaviorSubject<User>(parsedCurrentUser);
        this.currentUser = this.currentUserSubject.asObservable();

        const storedPointsUser = sessionStorage.getItem('currentPoints')
        this.currentPointsSubject = new BehaviorSubject<any>(isPlatformBrowser(this.platformId) ? (storedPointsUser ? JSON.parse(storedPointsUser): null) :null);
        this.currentPoints = this.currentPointsSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentPointsValue(): any {
        return this.currentPointsSubject.value;
    }

    login(data: any) {
        let url_api = this.URL + 'api/auth/signin'
        return this.http.post<any>(url_api, data)
        .pipe(map(user => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            if (this.notificationService.permitiNotificacion.value == '1') {
                this.notificationService.requestPushNotificationsPermission(this.currentUserValue);
            }
            return user;
        }));
    }

    public auxreturnUrl = ''
    public auxcodeListPrice = ''  

    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next({user:null});
        this.router.navigate(['/auth/ingresar'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    }

    registerCSM(data: any) {
        let url_api = this.URL + 'api/em/auth/register_csm'
        return this.http.post<any>(url_api, data)
    }  

    updateCurrentUser(user: any) {
        let currentUserString = localStorage.getItem('currentUser');
        let current = currentUserString ? JSON.parse(currentUserString) : { };

        current.user = user
        localStorage.setItem('currentUser', JSON.stringify(current));
        this.currentUserSubject.next(current);
    }

    updateData(id:any, data: any) {
        return this.http.put(`${this.URL}api/em/auth/update_csm/${id}`, data);
    }

    getUsetToken(data: any) {
        let url_api = this.URL + 'api/em/auth/getusertoken'
        return this.http.post<any>(url_api, data)
    }

    //verificar otp
    sendEmailVerificationCode(data: any){
        let url_api = this.URL + 'api/em/auth/sendEmailCode'
        return this.http.post<any>(url_api, data)
    }

    verificationCode(data: any){
        let url_api = this.URL + 'api/em/auth/verifyEmail'
        return this.http.post<any>(url_api, data)
    }

    //OTP
    generateOtp(data: any){
        let url_api = this.URL + 'api/OTP/sendOTP'
        return this.http.post<any>(url_api, data)
    }

    validateOtp(data: any){
        let url_api = this.URL + 'api/OTP/validateOTP'
        return this.http.post<any>(url_api, data)
    }
}