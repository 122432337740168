import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, map, Observable } from "rxjs"
import { Banner } from "src/app/core/models/banner.model"
import { Category } from "src/app/core/models/category-model"
import { PROVIDER } from "src/environments/config"
import { environment } from "src/environments/environment"
import { ModeOrderService } from "./mode-order.service"
import { MatSnackBar } from "@angular/material/snack-bar"
import { AuthenticationService } from "../authentication/authentication.service"
import { tap } from 'rxjs/operators';

interface AdditionalDetail {
    code: string;
    name: string;
    price: string;
    quantity: number;
    required: string;
  }
  
  interface MaterialJSON {
    nameProduct: string;
    price: number;
    quantity: number;
    aditionalDetails: AdditionalDetail[];
  }
  
export class Data {
    constructor(public categories: Category[],
        public compareList: any[],
        public wishList: any[],
        public cartList: any[],
        public totalPrice: number,
        public totalCartCount: number) { }
}

@Injectable({ providedIn: 'root' })
export class EcommerceService {
    
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        0, //totalPrice,
        0 //totalCartCount
    )

    totalPriceChange = new BehaviorSubject<number>(this.Data.totalPrice);
    public cartListChange = new BehaviorSubject<any[]>(this.Data.cartList);

    domain = environment.domain
    url_s3 = environment.url_amazon_s3

    addChange = new BehaviorSubject<any>(null) // AUXILIAR PARA DETECTAR cuando un producto fue añadido

    _user: any = null

    constructor(
        public http: HttpClient,
        private authService: AuthenticationService,
        private modeService: ModeOrderService,
        public snackBar: MatSnackBar,
    ) {
        this.authService.currentUser.subscribe(res => {
            this._user = res == null ? null : res.user
            console.log('this._user',this._user)
        })

        this.defaultStorageLocalProduct()
    }

    //start storage
    defaultStorageLocalProduct() {
        const cartProduct = localStorage.getItem("cartProduct");
        if (cartProduct === null || cartProduct === undefined) {
            this.setStorageData();
        }
        this.getLocalCart();
    }

    setStorageData() {
        localStorage.setItem("cartProduct", JSON.stringify(this.Data));  
    }

    getLocalCart() {
        const cartProduct = localStorage.getItem("cartProduct");
        this.Data = cartProduct ? JSON.parse(cartProduct) : [];
    }
    //end storage

    getBanners(): Observable<Banner[]> {
        return this.http.get<Banner[]>(this.domain + 'api/em/slides/get/horizontal/' + (PROVIDER.providerId == null ? '' : PROVIDER.providerId))
    }

    getCategories(): Observable<Category[]> {
        let url = this.domain + 'api/em/material_group/get_cb/' + (PROVIDER.providerId == null ? '' : PROVIDER.providerId) + (this.modeService.currentModeOrderValue == null ? '' : (!this.modeService.currentModeOrderValue.storeSelected.id ? '' : '/' + this.modeService.currentModeOrderValue.storeSelected.id + '/' + (this.modeService.currentModeOrderValue.orderSelected == 'PD' ? 'PD' : this.modeService.currentModeOrderValue.subOrderSelected)))
        return this.http.get<Category[]>(url);
    }

    getAllProducts(filter:any, skip: any, take:any): Observable<any[]> {
        let url = this.domain + `api/em/material/paginate?skip=${skip}&take=${take}`
        return this.http.post<any[]>(url, filter)
    }

    public getProductById(code: string, type: string, officeCode: string): Observable<any> {
        //TO DO Agregar tipo de dato Producto
        return this.http.get<any>(this.domain + `api/em/material/get/${code}/${type}/${officeCode}`)
    }

    getPathImageSlides(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/banner/' + image
    }

    getPathCategory(image: string){
        let url_amazon = this.url_s3
        return url_amazon + '/category/' + image
    }

    // IMAGENES PRODUCTOS
    public getPathImageOriginal(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/original/' + image
    }

    public getPathImageSmall(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/products/150X150/' + image
    }
    
    public getPathImageMedium(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/products/500X500/' + image
    }
    
    public getPathImageBig(image: string) {
        let url_amazon = this.url_s3
        return url_amazon + '/products/1024X1024/' + image
    }

    public getSuggested(data:any) {
        return this.http.get<any[]>(this.domain + 'api/em/material/get_suggested');
    }

    addToCart(product: any) {
        let message, status;

        this.Data.totalPrice = 0;
        this.Data.totalCartCount = 0;

        //Agregar el producto al carrito
        let parseItem = {
            id: null,
            provider_id: PROVIDER.providerId,
            user_id:  this._user ? this._user.id : null,
            pickup: this.modeService.currentModeOrderValue == null ? null : (this.modeService.currentModeOrderValue.orderSelected == 'pickup' ? this.modeService.currentModeOrderValue.subOrderSelected : this.modeService.currentModeOrderValue.orderSelected),
            quantity: product.cartCount,
            price: product.newPrice,
            material_code: product.code,
            material_json: JSON.stringify(product),
            material_aditional: null,
            storeId: this.modeService.currentModeOrderValue == null ? null : (this.modeService.currentModeOrderValue.orderSelected == 'pickup' ? this.modeService.currentModeOrderValue.storeSelected.id : (this.modeService.currentModeOrderValue.storeSelected.id))
        }

        this.Data.cartList.push(parseItem)        
 
        this.Data.cartList.forEach(product => {
            this.Data.totalPrice = this.Data.totalPrice + (product.quantity * Number(product.price));
            this.Data.totalCartCount = this.Data.totalCartCount + product.quantity;
        });
        this.totalPriceChange.next(this.Data.totalPrice); 
        message = 'Producto agregado a tu Carrito.';
        status = 'success';
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
        this.addChange.next(true)
        this.updateCartService(parseItem)
    }

    updateCartService(parseItem: any){
        if(this._user){
            this.addCart(parseItem).subscribe((res:any) => { //this.Data.cartList
                this.getCart().subscribe((cartRes: any[]) => {
                    this.Data.cartList = cartRes ? cartRes : [];
                    this.updateTotalAndCount();
                    this.setStorageData()
                }, error => {
                    console.error('Error al obtener el carrito:', error);
                });
            },error => {
                console.log("Eeer", error)
            })
        }else{
            //this.setStorageData()
        }
    }

    updateTotalAndCount() {
        this.Data.totalPrice = 0;
        this.Data.totalCartCount = 0;
        this.Data.cartList.forEach(product => {
            this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * Number(product.price));
            this.Data.totalCartCount = this.Data.totalCartCount + product.quantity;
        });
        this.setStorageData()
    }

    getCart(){
        let send = {
            pickup: this.modeService.currentModeOrderValue == null ? null : (this.modeService.currentModeOrderValue.orderSelected == 'pickup' ? this.modeService.currentModeOrderValue.subOrderSelected : this.modeService.currentModeOrderValue.orderSelected),
            userid: this._user.id,
            providerid: PROVIDER.providerId
        }

        return this.http.post<any[]>(this.domain + 'api/em/shopping_bag/get/', send).pipe(
            map((res: any[]) => {
                return res.map((product: any) => {
                    if (typeof product.material_json === 'string' && product.material_json) {
                        try {
                            product.material_json = JSON.parse(product.material_json); // Parseamos el JSON
                        } catch (error) {
                            console.error('Error al parsear material_json', error);
                            product.material_json = {}; // Si hay error, lo dejamos como un objeto vacío
                        }
                    }
                    return product;
                });
            })
        );
    }

    addCart(data: any){
        const url_api =  this.domain + 'api/em/shopping_bag/set/'
        return this.http.post(url_api, data);
    }
    
    removeCart(id: number) { 
        const url_api = this.domain + `api/em/shopping_bag/delete/${id}`; 
        return this.http.delete(url_api).pipe(
            tap(() => {
                // Actualiza la lista del carrito después de eliminar el producto
                this.Data.cartList = this.Data.cartList.filter(product => product.id !== id);
                console.log("desde eliminar", this.Data.cartList);
                
                // Actualiza el total
                this.updateTotalPrice();
                
                this.totalPriceChange.next(this.Data.totalPrice); 
                this.cartListChange.next(this.Data.cartList); // emite el nuevo carrito
                this.updateTotalAndCount(); // se actualiza el total y conteo
                this.setStorageData(); // guarda el carrito en el almacenamiento
            })
        );
    }
    
    //metodo para actualizar el total del precio
    private updateTotalPrice() {
        this.Data.totalPrice = this.Data.cartList.reduce((total, product) => {
            return total + product.price * product.quantity; 
        }, 0);
    } 

    private deliveryHour: string | null = null;
    private deliveryName: string | null = null;

    setDeliveryHour(hour: string | null) {
        this.deliveryHour = hour; 
    }

    getDeliveryHour(): string | null {
        return this.deliveryHour;
    }

    setDeliveryName(name: string) {
        this.deliveryName = name;
      }
    
    getDeliveryName(): string | null {
        return this.deliveryName;
    }

    private additionalDetails: MaterialJSON[] = [];
    setAdditionalDetails(details: any[]) {
      this.additionalDetails = details;
    }
  
    getAdditionalDetails(): MaterialJSON[] {
        return this.additionalDetails; 
      }

    getDataByCupon(coupon: string) {
        const url_api = this.domain + `api/em/fidelization/cupon_verified/${coupon}`
        return this.http.get(url_api);
    }
}